<template>
  <div class="UnshippedOrderModification">
    <el-page-header @back="$router.go(-1)" content="订单修改"></el-page-header>
    <div class="f-w-b">
      <NavigationBar></NavigationBar>
      <div class="main">
        <div class="banner">
          {{ enterpriseStateInfo.enterpriseName }}
        </div>
        <div class="boxShadow infoTable">
          <div class="title">订单信息</div>
          <div class="mb-30">
            <div v-for="(item, index0) of baseInfo" :key="index0 + 1">
              <div v-if="item.value" class="f-c-b titleItem">
                <span class="titval">{{ item.title }}</span>
                <span class="value">{{ item.value }}</span>
              </div>
            </div>
          </div>
          <div class="f-c-b w--100 mb-20">
            <div class="title ">商品明细 </div>
            <div class="addCustomerBtn" @click="ChooseProductDialogShow = true"></div>
          </div>
          <TableList :showDivider="false" :showList="false"
            v-show="orderDetailInfo.orderDetailsList && orderDetailInfo.orderDetailsList.length">
            <ModifiableProductDetailTable slot="definedList" :List="orderDetailInfo.orderDetailsList"
              :isShowResetBtn="true" @changeNumberInput="changeNumberInput" @delectSelectProduct="delectSelectProduct"
              @resetBtn="resetBtn">
            </ModifiableProductDetailTable>
          </TableList>
          <el-form :model="orderDetailInfo" label-width="110px" class="Modifi-order-form pb-30 f-w">
            <el-form-item class="w--50" v-for="(item, index) of Form" :label="item.label" :prop="item.prop" :key="index">
              <!-- 计价货币  -->
              <div class="f-c" v-if="item.prop == 'valuationCurrency'">
                <SelectOption style="position: relative;" :selectModel="orderDetailInfo[item.prop]" :Option="CurrencyOption"
                  :type="'ValuationCurrency'" @selectValuationCurrency="selectValuationCurrency"></SelectOption>
                <div class="Currency_tips"
                  v-show="(orderDetailInfo.valuationCurrency != orderDetailInfo.freightCurrency)">
                  与运费货币不一致
                </div>
              </div>
              <!-- 运费货币  -->
              <div class="f-c" v-else-if="item.prop == 'freightCurrency'">
                <SelectOption style="position: relative;" :selectModel="orderDetailInfo[item.prop]" :Option="CurrencyOption" :type="'FreightCurrency'"
                  @selectFreightCurrency="selectFreightCurrency">
                </SelectOption>
                <div class="Currency_tips"
                  v-show="(orderDetailInfo.freightCurrency != orderDetailInfo.valuationCurrency)">
                  与计价货币不一致
                </div>
              </div>
              <el-input v-else-if="item.label == '订单总额：' || item.label == '运费金额：'"
                v-model.trim="orderDetailInfo[item.prop]" placeholder="请输入">
              </el-input>
            </el-form-item>
          </el-form>

          <div class="title">订单备注</div>
          <TextArea class="textarea" placeholderValue="请输入订单备注" :modelValue="orderDetailInfo.orderNote" type="orderNote"
            @getTextAreaValue="getTextAreaValue" :maxlengthValue="300" :TextAreaisLimit="true"></TextArea>

          <div class="title mt-30">操作备注</div>
          <TextArea class="textarea" placeholderValue="请输入操作备注" :modelValue="orderDetailInfo.modifyNote" type="modifyNote"
            @getTextAreaValue="getTextAreaValue" :maxlengthValue="100" :TextAreaisLimit="true"></TextArea>
        </div>
        <div v-show="orderDetailInfo.freightCurrency == orderDetailInfo.valuationCurrency" class="f-c-c w--100 mb-40 mt-30">
          <div class="btn" @click="reviseOrderActive">订单修改</div>
        </div>
      </div>
    </div>




    <!-- <TableList :showDivider="false" :ItemInfoColor="'#888'" :list="baseInfo"></TableList> -->
    <!-- <div class="f-c-b">
      <span>商品明细：</span>
      <div class="f1 add-btn">
        <el-button class="add-customer1" round @click="ChooseProductDialogShow = true"><i
            class="el-icon-circle-plus-outline"></i></el-button>
      </div>
    </div> -->
    <!-- <TableList :showDivider="false" :showList="false"
      v-show="orderDetailInfo.orderDetailsList && orderDetailInfo.orderDetailsList.length">
      <ModifiableProductDetailTable slot="definedList" :List="orderDetailInfo.orderDetailsList" :isShowResetBtn="true"
        @changeNumberInput="changeNumberInput" @delectSelectProduct="delectSelectProduct" @resetBtn="resetBtn">
      </ModifiableProductDetailTable>
    </TableList> -->
    <!-- <el-form style="margin-top:50px" :model="orderDetailInfo" label-width="150px">
      <el-form-item v-for="(item, index) of Form" :label="item.label" :prop="item.prop" :key="index">

        <div class="f-c" v-if="item.prop == 'valuationCurrency'">
          <SelectOption :selectModel="orderDetailInfo[item.prop]" :Option="CurrencyOption" :type="'ValuationCurrency'"
            @selectValuationCurrency="selectValuationCurrency"></SelectOption>
          <div class="Currency_tips" v-show="(orderDetailInfo.valuationCurrency != orderDetailInfo.freightCurrency)">
            与运费货币不一致
          </div>
        </div>

        <div class="f-c" v-else-if="item.prop == 'freightCurrency'">
          <SelectOption :selectModel="orderDetailInfo[item.prop]" :Option="CurrencyOption" :type="'FreightCurrency'"
            @selectFreightCurrency="selectFreightCurrency">
          </SelectOption>
          <div class="Currency_tips" v-show="(orderDetailInfo.freightCurrency != orderDetailInfo.valuationCurrency)">
            与计价货币不一致
          </div>
        </div>
        <el-input v-else-if="item.label == '订单总额：' || item.label == '运费金额：'" v-model.trim="orderDetailInfo[item.prop]"
          placeholder="请输入">
        </el-input>
        <TextArea v-else :modelValue="orderDetailInfo[item.prop]" :type="item.prop" @getTextAreaValue="getTextAreaValue"
          :maxlengthValue="item.label == '订单备注：' ? 300 : 100"></TextArea>
      </el-form-item>
    </el-form> -->
    <!-- <div class="f-c-c">
      <el-button style="width: 50%;" type="primary" round @click="reviseOrderActive"
        :disabled="orderDetailInfo.freightCurrency != orderDetailInfo.valuationCurrency">订单修改</el-button>
    </div> -->
    <ChooseProductBox :ChooseProductDialogShow="ChooseProductDialogShow" @closeProductDialog="closeProductDialog"
      @selectedProduct="selectedProduct"></ChooseProductBox>
  </div>
</template>

<script>


import { TableColumn1 } from '@/utils/TableColumn'
import { CurrencyOption } from '@/utils/SelectOption'

export default {
  name: 'UnshippedOrderModification',
  components: {
    ChooseProductBox: () => import(/* webpackChunkName: "ChooseProductBox" */ "./components/ChooseProductBox.vue"),
    NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
  },
  data() {
    return {
      baseInfo: [],
      orderDetailInfo: {},
      TableColumn: TableColumn1,
      CurrencyOption: CurrencyOption,
      isShowResetBtn: false,
      Form: [{
        label: "计价货币：",
        prop: "valuationCurrency"
      }, {
        label: "订单总额：",
        prop: "realityAmount"
      }, {
        label: "运费货币：",
        prop: "freightCurrency"
      }, {
        label: "运费金额：",
        prop: "freightAmount"
      },
        // {
        //   label: "订单备注：",
        //   prop: "orderNote",
        // }, {
        //   label: "订单操作备注：",
        //   prop: "modifyNote",
        // }
      ],
      ChooseProductDialogShow: false,//是否显示产品列表盒子
      enterpriseStateInfo: {}
    }
  },
  created() {
    let data = this.$getStorage('OrderDetailNeepModify'),
      { customerName, contact, contactPhone, customerCity, customerAddress, orderStatus,
        orderNumber, createDate, createName, customerId, orderDetailsList } = data,
      orderStatusText = this.$orderClass.getOrderState(orderStatus),
      address = customerCity + ' ' + customerAddress;
    this.baseInfo = [{
      "title": "客户名称",
      "value": customerName
    }, {
      "title": "联系人",
      "value": contact
    }, {
      "title": "联系电话",
      "value": contactPhone
    }, {
      "title": "地址",
      "value": address
    }, {
      "title": "订单编号",
      "value": orderNumber
    }, {
      "title": "订单状态",
      "value": orderStatusText
    }, {
      "title": "创建人",
      "value": createName
    }, {
      "title": "创建时间",
      "value": createDate
    }];
    data.orderDetailsList.forEach((item) => {
      item.originUnitPrice = item.unitPrice
      item.LastTransactionPrice = 0,
        item.valuationCurrency = ''
      item.visibleProductName = false;
      item.visibleProductNumber = false;
      item.visibleUnitPrice = false;
    })
    this.orderDetailInfo = data;
    this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
    this.$setStorage('chooseCustomerId', customerId)
    this.$setStorage('selectProductList', orderDetailsList)
  },
  methods: {
    // 获取textarea内容
    getTextAreaValue(data) {
      this.orderDetailInfo[data.type] = data.value
    },
    // 改变数组输入框
    changeNumberInput(currentValue, productId, type) {
      let OrderDetails = this.orderDetailInfo,
        list = OrderDetails.orderDetailsList,
        priseTotal = 0;
      list.forEach((item, index) => {
        if (item.productId == productId) {
          // 改变产品价格
          if (type == 'changeUnitPrice') {
            list[index].amount = item.quantity * currentValue
            list[index].auAmount = item.quantity * currentValue
          }
          //改变数量
          if (type == 'changeQuantity') {
            list[index].amount = item.unitPrice * currentValue
            list[index].auAmount = item.unitPrice * currentValue
          }
          // 改变小计
          if (type == 'changeRealAmount') {
            list[index].amount = currentValue
          }
        }
        priseTotal += list[index].amount
      })
      OrderDetails.realityAmount = Number(priseTotal);
      OrderDetails.autoAmount = Number(priseTotal);
      OrderDetails.orderDetailsList = list;
      this.orderDetailInfo = OrderDetails
    },
    // 删除选中的产品
    delectSelectProduct(productId) {
      let arr = this.orderDetailInfo.orderDetailsList.filter(item => item.productId != productId);
      let priseTotal = 0;
      arr.forEach((item, index) => {
        priseTotal += arr[index].amount
      })
      this.orderDetailInfo.autoAmount = priseTotal.toFixed(2);
      this.orderDetailInfo.realityAmount = priseTotal.toFixed(2);
      this.orderDetailInfo.orderDetailsList = arr;
      this.$setStorage('selectProductList', arr)

    },
    // 重置按钮
    resetBtn() {
      let list = this.$getStorage('OrderDetailNeepModify').orderDetailsList,
        OrderDetailsUseReturnDoc = this.orderDetailInfo;
      OrderDetailsUseReturnDoc.orderDetailsList = list;
      OrderDetailsUseReturnDoc.realityAmount = this.$getStorage('OrderDetailNeepModify').realityAmount
      this.$setStorage('selectProductList', list)

    },
    // 选择运费货币
    selectFreightCurrency(value) {
      this.orderDetailInfo.freightCurrency = value;
    },
    // 选择计价货币
    selectValuationCurrency(value) {
      this.orderDetailInfo.valuationCurrency = value;
    },
    // 获取参数
    getParam() {
      let that = this,
        { realityAmount, valuationCurrency, orderNote, freightAmount, freightCurrency, modifyNote,
          orderDetailsList, autoAmount, orderId } = that.orderDetailInfo;
      return {
        lastModifyUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        orderId,
        realityAmount: Number(realityAmount),//订单实际金额
        autoAmount: Number(autoAmount),//订单实际金额
        valuationCurrency,//计价货币
        orderNote,//订单备注
        freightAmount: Number(freightAmount),//运费金额
        freightCurrency,//运费货币:人民币（￥）、USD($)
        modifyNote,//操作备注
        orderDetailsList: orderDetailsList,
      }
    },
    // 选中产品
    selectedProduct(data) {
      let that = this,
        list = that.orderDetailInfo.orderDetailsList,
        priseTotal = 0,
        { valuationCurrency, ...newData } = data;
      if (valuationCurrency != this.orderDetailInfo.valuationCurrency) {
        that.ChooseProductDialogShow = false;//隐藏产品列表盒子
        that.$confirm(`${data.productName}与当前的结算货币类型不一致，默认将其单价置零，请手动设置该商品单价`, '提示', {
          showCancelButton: false
        }).then(() => {
          newData.unitPrice = Number(0)
          newData.amount = Number(0)
          newData.auAmount = Number(0)
          newData.quantity = Number(1)
          list.push(newData);
          that.$setStorage('selectProductList', list)
        }).catch(() => { })
      } else {
        list.push(newData);
        that.$setStorage('selectProductList', list)
        that.ChooseProductDialogShow = false;//隐藏产品列表盒子
      }
      list.forEach((item, index) => {
        priseTotal += list[index].amount
      })
      that.orderDetailInfo.orderDetailsList = list;
      that.orderDetailInfo.realityAmount = priseTotal.toFixed(2);
    },
    // 关闭产品列表盒子
    closeProductDialog(data) {
      this.ChooseProductDialogShow = data
    },
    // 订单修改
    reviseOrderActive() {
      let that = this,
        data = that.getParam();
      that.$confirm('是否确定修改？', '提示').then(() => {
        if (that.orderDetailInfo.orderDetailsList.length == 0) {
          return that.$common.message('请选择商品', 'warning', 1200)
        }
        that.$http.reviseOrderActive(data).then(res => {
          if (res.code == 200) {
            that.$message({ message: res.msg, type: 'success' });
            that.$common.DelayedBack();
          }
        })
      }).catch(() => { })
    },
  },
  beforeDestroy() {
    this.$removeStorage('OrderDetailNeepModify')
    this.$removeStorage('selectProductList')
    this.$removeStorage('chooseCustomerId')
  },
}

</script>
<style lang='less' scoped>
.UnshippedOrderModification {
  .main {
    width: 1000px;

    .banner {
      background: url('../../../assets/supply/supplyBanner.png');
      height: 100px;
      color: #FFFFFF;
      font-size: 30px;
      line-height: 100px;
      text-align: center;
      margin-bottom: 40px;
    }

    .infoTable {
      width: 899px;
      padding: 40px;
      border-radius: 10px;

      .title {
        margin-bottom: 22px;
        border-left: 4px solid #0363FA;
        padding-left: 10px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;

      }
      .textarea {
        font-size: 18px;
      }

      .Modifi-order-form {
        .formTitle {
          width: 100%;
          margin-bottom: 22px;
          border-left: 4px solid #0363FA;
          padding-left: 10px;
          font-size: 24px;
          font-weight: 500;
          color: #333333;
        }
        .Currency_tips {
        position: absolute;
        top: 30px;
        left: 0px;
        color: red;
      }
      }

      .addCustomerBtn {
        width: 60px;
        height: 50px;
        background: url('../../../assets/supply/addCustomerBtn.png');
        cursor: pointer;
      }


      .titleItem {
        padding: 10px 30px;
        width: 840px;
        min-height: 30px;
        border: 1px solid #f2f2f2;
        margin-bottom: 5px;

        .titval {
          font-size: 16px;
          color: #999;
          font-weight: 400;
          width: 50%;
        }

        .value {
          line-height: 20px;
          font-size: 16px;
          color: #333;
          font-weight: 400;
          width: 50%;
        }
      }

      .talTit {
        border-top: 1px solid #EDECEC;
        border-left: 1px solid #EDECEC;
        background: #F8F8F8;
        // border-radius: 5px 5px 0px 0;

        span {
          width: calc(100% / 6 - 6px);
          text-align: center;
          line-height: 60px;
          color: #333;
          font-weight: 500;
          font-size: 18px;
          border-right: 1px solid #EDECEC;
        }
      }

      .talVal {
        // min-heightht: 60px;
        background: #fff;
        border-left: 1px solid #EDEDED;
        // border-right: 1px solid #EDEDED;
        border-bottom: 1px solid #EDEDED;

        span {
          width: calc(100% / 6 - 6px);
          text-align: center;
          line-height: 60px;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          border-right: 1px solid #EDEDED;
        }

        div {
          width: calc(100% / 6 - 6px);
          text-align: center;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          border-right: 1px solid #EDEDED;
          word-break: break-all;
          word-wrap: break-word;
          line-height: 25px;
        }
      }

      .modifyNote {
        padding: 30px;
        // height: 230px;
        background: #F3F8FE;
        border-radius: 5px;
        color: #666666;
        white-space: pre-line;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }
    }

    .btn {
      width: 160px;
      height: 50px;
      background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
      border-radius: 10px;
      color: #FFF;
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
  }

  .add-btn {
    text-align: right;

    .add-customer1 {
      padding: 10px 20px;
      border-radius: 50px;
      margin-bottom: 10px;

      .el-icon-circle-plus-outline {
        font-size: 22px;
      }
    }
  }

  .el-form {
    margin-top: 50px !important;

    .Currency_tips {
      margin-left: 50px;
      color: red;
    }
  }
}
</style>